/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { Flex } from 'rebass';
import ReactMarkdown from 'react-markdown';

import { Cross } from '../components/Cross';
import { P, H2, H4 } from '../components/Text';
import { Container } from '../components/Container';
import { AboutLayout } from '../layouts/AboutLayout';
import { useTranslation } from '../i18n/TranslationContext';

interface QuestionCardProps {
  question: string;
  answer: string;
}
const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  answer,
  ...restProps
}) => {
  const [isActive, setActive] = useState(false);

  return (
    <div {...restProps}>
      <Flex
        alignItems="baseline"
        justifyContent="space-between"
        role="button"
        aria-expanded={isActive}
        sx={{ cursor: 'pointer' }}
        onClick={() => setActive(s => !s)}
      >
        <H4 sx={{ width: '90%', fontSize: ['20px', '20px', 3] }}>{question}</H4>
        <Cross
          sx={{ transform: isActive ? 'rotateZ(45deg)' : 'rotateZ(0deg)' }}
        />
      </Flex>
      {isActive && (
        <ReactMarkdown
          source={answer}
          linkTarget="_blank"
          sx={{
            lineHeight: '22px',
            mt: 3,
            variant: 'text.body',
            whiteSpace: 'pre-line',
            a: { variant: 'variants.link' },
            p: { mb: [1, 3, 3] },
          }}
        />
      )}
    </div>
  );
};

type CovidQuestion = {
  id: string;
  question: string;
  answer: string;
};
interface CovidFaqPageProps {
  pageContext: {
    covidQuestions: CovidQuestion[];
  };
}
const CovidFaqPage: React.FC<CovidFaqPageProps> = ({
  pageContext: { covidQuestions },
}) => {
  const t = useTranslation();
  return (
    <AboutLayout>
      <Container>
        <H2 sx={{ fontStyle: 'normal', px: [0, 0, 5] }}>{t('faq_heading')}</H2>
      </Container>
      <div
        sx={{
          mx: [0, 'auto'],
          px: [0, 0, 3],
          maxWidth: '1118px',
        }}
      >
        <hr
          sx={{
            borderStyle: 'solid',
            borderColor: 'text',
            mt: '50px',
            mb: 3,
            borderBottom: 'none',
          }}
        />
      </div>
      <Container>
        <div sx={{ mx: 'auto', maxWidth: ['742px'] }}>
          {covidQuestions.map(({ id, question, answer }, index) => (
            <QuestionCard
              key={id}
              sx={{
                py: 3,
                borderBottom: '1px solid',
                borderBottomColor:
                  index === covidQuestions.length - 1 ? 'transparent' : 'text',
              }}
              question={question}
              answer={answer}
            />
          ))}
        </div>
      </Container>
    </AboutLayout>
  );
};

export default CovidFaqPage;
